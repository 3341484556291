import "@/globals.css";
import type { AppProps } from "next/app";
import Nav from "@/components/Nav";
import { AnimatePresence } from "framer-motion";
import NextNProgress from 'nextjs-progressbar';
// import { GetServerSideProps } from "next";
// import { unstable_getServerSession } from "next-auth/next"
// import { authOptions } from "./api/auth/[...nextauth]"
// import {SessionProvider} from "next-auth/react"
// import { Session } from "next-auth";
// Router.events.on("routeChangeStart", () => NProgress.start());
// Router.events.on("routeChangeComplete", () => NProgress.done());
// Router.events.on("routeChangeError", () => NProgress.done());
import { Analytics } from '@vercel/analytics/react';

function App({ Component, pageProps, router }: AppProps & {
    // session: Session
}) {


    return (
        <div className="text-black dark:text-white flex flex-row w-full h-auto bg-gradient-to-bl from-white to-[#fff] dark:from-black dark:to-[#0d131f] min-h-screen max-h-screen overflow-y-auto">
            <NextNProgress color="#b84ca0" />
            <Analytics />

            <Nav />


            <div className="w-[100%] mt-24">
                <AnimatePresence mode="wait">

                    <Component {...pageProps} key={router.pathname} />
                </AnimatePresence>
                {/* <Footer /> */}
            </div>
        </div>
    );
}
// export const getServerSideProps: GetServerSideProps = async (context) => {
//     return {
//       props: {
//         session: await unstable_getServerSession(
//           context.req,
//           context.res,
//           authOptions
//         ),
//       },
//     }
//   }
  
  
export default App;
