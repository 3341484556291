import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { FiSun, FiMoon } from "react-icons/fi";
import reactDom from "react-dom";
const ThemeToggle = () => {
    const [theme, setTheme] = useState<string>("dark");
    const [domNode, setDomNode] = useState<HTMLElement|undefined>(undefined)
    useEffect(() => {
        if(document.getElementById("__next")) {
            setDomNode(document.getElementById("__next") as HTMLElement)

        }
        let storedTheme = localStorage.getItem("theme") as string;

        if (!storedTheme) {
            localStorage.setItem("theme", theme);
        } else {
            setTheme(storedTheme);
            storedTheme === "light" ? document.querySelector("html")?.classList.remove("dark") : null;
            storedTheme === "dark" ? document.querySelector("html")?.classList.add("dark") : null;
        }
    }, []);

    const changeTheme = (theme: string) => {
        let newTheme = theme === "light" ? "dark" : "light";

        localStorage.setItem("theme", newTheme);
        setTheme(newTheme);
        newTheme === "light"
            ? document.querySelector("html")?.classList.remove("dark")
            : document.querySelector("html")?.classList.add("dark");
    };

    return (
        <><button
            className="p-2 rounded-md bg-transparent hover:bg-black/5 dark:hover:bg-white/5 cursor-pointer"
            onClick={() => changeTheme(theme)}
        >
            {theme === "light" && <FiSun className="text-black w-6 h-6 xs:w-5 xs:h-5" />}
            {theme === "dark" && <FiMoon className="text-white w-6 h-6 xs:w-5 xs:h-5" />}
        </button>
        {domNode && reactDom.createPortal(
            <Toaster toastOptions={{
            duration: 7000,
            style: {
                background: theme === "dark" ? "rgb(32, 32, 32)" : "rgb(255, 255, 255)",
                color:theme === "dark" ? "#fff" : "#000"
            }
           }} />,
           domNode)}
        </>
    );
};

export default ThemeToggle;
