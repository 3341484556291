import { toast } from "react-hot-toast";
import { IconType } from "react-icons"
import { SiTwitter, SiTwitch, SiDiscord, SiPaypal, SiCashapp } from "react-icons/si";
import { FiMail } from "react-icons/fi";
export const twitchHandle = "ArcticMursai"
export const twitch = `https://twitch.tv/${twitchHandle}`
export const twitterHandle = "ArcticMursai"
export const twitter = `https://twitter.com/${twitterHandle}`
export const discord = "https://discord.gg/QMtWrhMK5h"
export const discordTag = "mursai"
export const businessEmail = "business@mursai.stream"
// export const coaching = "https://docs.google.com/forms/d/e/1FAIpQLScL7cdCe38YfqxlulFmrlPi12ov2AfQeQAK-w9TGo8Ng-zIsQ/viewform"
export const cashapp = "$Mursai"
export const paypal = `https://paypal.me/AmirChallah`
export const coachingMaxElo = 3000;
export const weaponsOptions = [
    {
        value: "Axe",
        label: "Axe"
    },
    {
        value: "Rocket Lance",
        label: "Lance"
    },
    {
        value: "Spear",
        label: "Spear"
    },
    {
        value: "Hammer",
        label: "Hammer"
    },
    {
        value: "Sword",
        label: "Sword"
    },
    {
        value: "Blasters",
        label: "Blasters"
    },
    {
        value: "Katars",
        label: "Katars"
    },
    {
        value: "Bow",
        label: "Bow"
    },
    {
        value: "Gauntlets",
        label: "Gauntlets"
    },
    {
        value: "Scythe",
        label: "Scythe"
    },
    {
        value: "Cannon",
        label: "Cannon"
    },
    {
        value: "Orb",
        label: "Orb"
    },
    {
        value: "Greatsword",
        label: "Greatsword"
    },
    {
        value: "Boots",
        label: "Battle Boots"
    },
];
export const legendsOptions = [
    {
        label: "Bödvar",
        value: 3
    },
    {
        label: "Cassidy",
        value: 4
    },
    {
        label: "Orion",
        value: 5
    },
    {
        label: "Lord Vraxx",
        value: 6
    },
    {
        label: "Gnash",
        value: 7
    },
    {
        label: "Queen Nai",
        value: 8
    },
    {
        label: "Hattori",
        value: 10
    },
    {
        label: "Sir Roland",
        value: 11
    },
    {
        label: "Scarlet",
        value: 12
    },
    {
        label: "Thatch",
        value: 13
    },
    {
        label: "Ada",
        value: 14
    },
    {
        label: "Sentinel",
        value: 15
    },
    {
        label: "Lucien",
        value: 9
    },
    {
        label: "Teros",
        value: 16
    },
    {
        label: "Brynn",
        value: 19
    },
    {
        label: "Asuri",
        value: 20
    },
    {
        label: "Barraza",
        value: 21
    },
    {
        label: "Ember",
        value: 18
    },
    {
        label: "Azoth",
        value: 23
    },
    {
        label: "Koji",
        value: 24
    },
    {
        label: "Ulgrim",
        value: 22
    },
    {
        label: "Diana",
        value: 25
    },
    {
        label: "Jhala",
        value: 26
    },
    {
        label: "Kor",
        value: 28
    },
    {
        label: "Wu Shang",
        value: 29
    },
    {
        label: "Val",
        value: 30
    },
    {
        label: "Ragnir",
        value: 31
    },
    {
        label: "Cross",
        value: 32
    },
    {
        label: "Mirage",
        value: 33
    },
    {
        label: "Nix",
        value: 34
    },
    {
        label: "Mordex",
        value: 35
    },
    {
        label: "Yumiko",
        value: 36
    },
    {
        label: "Artemis",
        value: 37
    },
    {
        label: "Caspian",
        value: 38
    },
    {
        label: "Sidra",
        value: 39
    },
    {
        label: "Xull",
        value: 40
    },
    {
        label: "Kaya",
        value: 42
    },
    {
        label: "Isaiah",
        value: 41
    },
    {
        label: "Jiro",
        value: 43
    },
    {
        label: "Lin Fei",
        value: 44
    },
    {
        label: "Zariel",
        value: 45
    },
    {
        label: "Rayman",
        value: 46
    },
    {
        label: "Dusk",
        value: 47
    },
    {
        label: "Fait",
        value: 48
    },
    {
        label: "Thor",
        value: 49
    },
    {
        label: "Petra",
        value: 50
    },
    {
        label: "Vector",
        value: 51
    },
    {
        label: "Volkov",
        value: 52
    },
    {
        label: "Onyx",
        value: 53
    },
    {
        label: "Jaeyun",
        value: 54
    },
    {
        label: "Mako",
        value: 55
    },
    {
        label: "Magyar",
        value: 56
    },
    {
        label: "Reno",
        value: 57
    },
    {
        label: "Munin",
        value: 58
    },
    {
        label: "Arcadia",
        value: 59
    },
    {
        label: "Ezio",
        value: 60
    },
    {
        label: "Tezca",
        value: 61
    },{
        label: "Thea",
        value: 62
    },
    {
        label: "Red Raptor",
        value: 63
    },
]
export const navLinks: Array<{
    name: string,
    link: string,
}> = [
        {
            name: "Home",
            link: "/"
        },
        {
            name: "Coaching",
            link: "/coaching"
        },

        // {
        //     name: "Community",
        //     link: "/community",
        // }
    ]
export const navContacts: Array<{
    title: string,
    href?: string,
    icon: IconType,
    onClick?: () => Promise<void>
}> = [
        {
            title: "Twitch",
            href: twitch,
            icon: SiTwitch
        },
        {
            title: "Twitter",
            href: twitter,
            icon: SiTwitter
        },
        {
            title: "Discord",
            href: discord,
            icon: SiDiscord
        },
        {
            title: "PayPal",
            href: paypal,
            icon: SiPaypal
        },
        {
            title: cashapp,
            icon: SiCashapp,
            onClick: async () => {
                try {
                    await navigator.clipboard.writeText(cashapp)
                    toast.success("Copied Cashtag to clipboard!")
                } catch (e) {
                    console.error(e)
                    toast.error("An error occured while trying to copy cashtag!")
                }
            }
        },
        {
            title: "Email",
            href: `mailto:${businessEmail}`,
            icon: FiMail
        }
    ]